/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable import/no-extraneous-dependencies */
import { getPublicCompressed } from '@toruslabs/eccrypto'
import { ADAPTER_EVENTS, CONNECTED_EVENT_DATA, UserInfo } from '@web3auth/base'
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider'
import { Web3Auth } from '@web3auth/modal'
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { getAccount } from '../helpers/ethers'

export interface AuthProviderData {
    web3auth: Web3Auth | null;
    provider: CONNECTED_EVENT_DATA | null;
    user: Partial<UserInfo> | null;
    onSuccessfulLogin: (
        data: CONNECTED_EVENT_DATA,
        user: Partial<UserInfo>
    ) => void;
    login: () => void;
    logout: () => void;
    getUserAddress: () => Promise<string>;
    appPublicKey: () => Promise<string>;
}

export const AuthProviderContext = React.createContext<AuthProviderData>({
	web3auth: null,
	provider: null,
	user: null,
	onSuccessfulLogin: () => {},
	login: () => {},
	logout: () => {},
	getUserAddress: async () => '',
	appPublicKey: async () => '',
})

const chainConfig = {
	chainNamespace: 'eip155',
	chainId: process.env.REACT_APP_CHAIN === 'matic' ? '0x89' : '0x13881', // hex of 137, polygon mainnet
	rpcTarget:
					process.env.REACT_APP_CHAIN === 'matic' ? 'https://rpc.ankr.com/polygon' : 'https://rpc.ankr.com/polygon_mumbai',
	// Avoid using public rpcTarget in production.
	// Use services like Infura, Quicknode etc
	displayName:
					process.env.REACT_APP_CHAIN === 'matic' ? 'Polygon Mainnet' : 'Polygon Testnet',
	// blockExplorer:
	//      process.env.REACT_APP_CHAIN === 'matic' ? 'https://polygonscan.com' : 'https://mumbai.polygonscan.com/',
	ticker: 'MATIC',
	tickerName: 'Matic',
}

const privateKeyProvider = new EthereumPrivateKeyProvider({
	config: {
		chainConfig: {
			chainNamespace: 'eip155',
			chainId:
                process.env.REACT_APP_CHAIN === 'matic' ? '0x89' : '0x13881', // hex of 137, polygon mainnet
			rpcTarget:
                process.env.REACT_APP_CHAIN === 'matic'
                	? 'https://rpc.ankr.com/polygon'
                	: 'https://rpc.ankr.com/polygon_mumbai',
			// Avoid using public rpcTarget in production.
			// Use services like Infura, Quicknode etc
			displayName:
                process.env.REACT_APP_CHAIN === 'matic'
            	? 'Polygon Mainnet'
                	: 'Polygon Testnet',
			// blockExplorer:
			//      process.env.REACT_APP_CHAIN === 'matic' ? 'https://polygonscan.com' : 'https://mumbai.polygonscan.com/',
			ticker: 'MATIC',
			tickerName: 'Matic',
		},
	},
})

// Initialize within your constructor
// const web3auth = new Web3Auth({
// 	clientId
// });
const web3auth = new Web3Auth({
	clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID as string, // Get your Client ID from Web3Auth Dashboard
	privateKeyProvider,
	chainConfig: {
		chainNamespace: 'eip155',
		chainId: process.env.REACT_APP_CHAIN === 'matic' ? '0x89' : '0x13881', // hex of 137, polygon mainnet
		rpcTarget:
            process.env.REACT_APP_CHAIN === 'matic' ? 'https://rpc.ankr.com/polygon' : 'https://rpc.ankr.com/polygon_mumbai',
		// Avoid using public rpcTarget in production.
		// Use services like Infura, Quicknode etc
		displayName:
            process.env.REACT_APP_CHAIN === 'matic' ? 'Polygon Mainnet' : 'Polygon Testnet',
		// blockExplorer:
		//      process.env.REACT_APP_CHAIN === 'matic' ? 'https://polygonscan.com' : 'https://mumbai.polygonscan.com/',
		ticker: 'MATIC',
		tickerName: 'Matic',
	},
	// authMode: 'WALLET',
})

interface Props {
    children?: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
	const [provider, setProvider] = useState<CONNECTED_EVENT_DATA | null>(null)
	const [user, setUser] = useState<Partial<UserInfo> | null>(null)

	const appPublicKey = async () => {
		const app_scoped_privkey = (await web3auth.provider?.request({
			method: 'eth_private_key', // use "private_key" for other non-evm chains
		})) as any
		if (!provider) {
			return ''
		}

		const app_pub_key = getPublicCompressed(
			Buffer.from(app_scoped_privkey.padStart(64, '0'), 'hex'),
		).toString('hex')
		return app_pub_key
	}

	const onSuccessfulLogin = useCallback(
		(data: CONNECTED_EVENT_DATA, user: Partial<UserInfo>) => {
			setProvider(data)
			setUser(user)
		},
		[],
	)

	const login = useCallback(() => {
		web3auth
			.connect()
			.then(() => {})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const logout = useCallback(() => {
		web3auth
			.logout()
			.then(() => {
				// login on logout
			})
			.catch((err) => {
				console.log('logout', err)
			})
	}, [])

	const getUserAddress = async () => {
		const privateKey = await web3auth.provider?.request({
			method: 'eth_private_key',
		})
		const address = await getAccount(privateKey as string)
		return address
	}

	const subscribeAuthEvents = useCallback(
		(web3auth: Web3Auth) => {
			web3auth.on(
				ADAPTER_EVENTS.CONNECTED,
				(data: CONNECTED_EVENT_DATA) => {
					web3auth.getUserInfo().then((user) => {
						onSuccessfulLogin(data, user)
					})
				},
			)

			web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
				setUser(null)
				setProvider(null)
			})

			web3auth.on(ADAPTER_EVENTS.ERRORED, (error: any) => {
				console.log(
					'some error or user have cancelled login request',
					error,
				)
			})
		},
		[onSuccessfulLogin],
	)

	useEffect(() => {
		subscribeAuthEvents(web3auth)

		web3auth.initModal()
	}, [])

	const ctx: AuthProviderData = useMemo(
		() => ({
			web3auth,
			provider,
			user,
			onSuccessfulLogin,
			login,
			logout,
			getUserAddress,
			appPublicKey,
		}),
		[
			web3auth,
			provider,
			user,
			onSuccessfulLogin,
			login,
			logout,
			getUserAddress,
			appPublicKey,
		],
	)

	return (
		<AuthProviderContext.Provider value={ctx}>
			{children}
		</AuthProviderContext.Provider>
	)
}

export const AuthConsumer = AuthProviderContext.Consumer
