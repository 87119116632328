/* eslint-disable import/no-extraneous-dependencies */
import './index.css'
import '@rainbow-me/rainbowkit/styles.css'

import { darkTheme, getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { WagmiProvider } from 'wagmi'
import { polygon } from 'wagmi/chains'

import App from './App'
import { Auth } from './AuthProvider'
import reportWebVitals from './reportWebVitals'

const config = getDefaultConfig({
	appName: 'SpaceDev Airdrop Landing',
	projectId: 'e03fabce55404e16f3053edddb084d18',
	chains: [polygon],
	ssr: false, // If your dApp uses server side rendering (SSR)
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
)
root.render(
	<React.StrictMode>
		<Auth>
			<WagmiProvider config={config}>
				<QueryClientProvider client={queryClient}>
					<RainbowKitProvider
						initialChain={polygon}
						theme={darkTheme({
							accentColor: '#5b21b6',
						})}
					>
						<App />
					</RainbowKitProvider>
				</QueryClientProvider>
			</WagmiProvider>
		</Auth>
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
