/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */

import { ConnectButton } from '@rainbow-me/rainbowkit'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { getAvailableTokens, redeemNft } from '../../api'
import { AuthProviderContext } from '../../AuthProvider/AuthProvider'
import { getMetadataUri } from '../../helpers/ethers'
import { Nft } from '../../types/Nft'
import EmptyState from '../EmptyState'
import Spinner from '../Spinner'

enum AddressType {
    Web3Auth = 'Web3Auth',
    Rainbow = 'Rainbow',
}

interface Props {
	tokenId: string | null;
}

const MintNft = ({ tokenId }: Props) => {
	const { address: rainbowAddress } = useAccount()
	const [nft, setNft] = useState<Nft | null>(null)
	const [txHash, setTxHash] = useState('')
	const [loading, setLoading] = useState(true)
	const [isMinting, setIsMinting] = useState(false)
	const [minted, setMinted] = useState(false)
	const { user, appPublicKey, getUserAddress } = useContext(AuthProviderContext)

	const getNft = async () => {
		try {
			setLoading(true)
			if (user?.email) {
				const availableTokens = await getAvailableTokens(user?.email)
				if (tokenId) {
					const nftFound = availableTokens.find((n) => n.tokenId === +tokenId)
					if (nftFound) {
						const uri = await getMetadataUri(nftFound.tokenId)
						const res = await axios.get(uri)
						setNft({ ...res.data })
					} else {
						setNft(null)
					}
				} else {
					console.log('no tokenid')
				}
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const handleClaim = async (addressType: AddressType) => {
		setIsMinting(true)
		try {
			let addressForMinting
			if (addressType === AddressType.Web3Auth) {
				addressForMinting = await getUserAddress()
			} else if (addressType === AddressType.Rainbow) {
				addressForMinting = rainbowAddress
			}
			const appPubKey = await appPublicKey()
			if (
				!appPubKey
                || !user?.idToken
                || !tokenId
                || !addressForMinting
			) {
				throw new Error('Error getting user credentials')
			}
			const res = await redeemNft(
				user.idToken,
				appPubKey,
				tokenId,
				addressForMinting,
			)
			setTxHash(res.transactionHash)
			setMinted(true)
		} catch (error) {
			console.log(error)
		} finally {
			setIsMinting(false)
		}
	}
	useEffect(() => {
		getNft()
	}, [])

	if (loading) return <>Loading...</>

	if (!nft) return <EmptyState />

	return (
		<div className="flex flex-row w-5/6 bg-white rounded-lg p-10">
			<div className="flex justify-between flex-col w-1/2 my-4 mx-5">
				<div>
					<div className="font-bold text-violet-800 text-4xl mb-4">
						{nft.name}
					</div>
					<div>
						<p className="text-xl select-none mt-4">
							{nft.description}
						</p>
					</div>
				</div>
				{minted ? (
					<div>
						<button
							type="button"
							className="font-bold mt-2 w-full bg-violet-800 text-white rounded-lg p-2 shadow-lg opacity-50"
							disabled
						>
							Minted
						</button>
					</div>
				) : isMinting ? (
					<Spinner />
				) : (
					<div className="bg-gray-100 p-4 rounded-md shadow-md mt-2">
						<h2 className="text-xl font-semibold text-violet-800 mb-3">
							Choose a wallet to claim your NFT
						</h2>

						{/* Connect Your Wallet */}
						<div className="mb-3">
							<h3 className="text-md font-medium text-gray-700 mb-1">
								Connect your own wallet
							</h3>
							<div className="mb-2">
								<ConnectButton
									showBalance={false}
									accountStatus="full"
								/>
							</div>
							<button
								type="button"
								onClick={() => handleClaim(AddressType.Rainbow)}
								className={`w-full py-2 px-3 text-md font-semibold rounded-md shadow-md text-white transition-all 
        ${
					isMinting || !rainbowAddress
						? 'bg-violet-400 opacity-50 cursor-not-allowed'
						: 'bg-violet-700 hover:bg-violet-800 focus:ring-2 focus:ring-violet-500'
					}
      `}
								disabled={isMinting || !rainbowAddress}
							>
								Mint with your own Wallet
							</button>
						</div>

						{/* Separator */}
						<div className="text-center my-4 text-gray-500">
							<span>OR</span>
						</div>

						{/* Use Web3Auth Wallet */}
						<div className="mb-3">
							<h3 className="text-md font-medium text-gray-700 mb-1">
								Or use the wallet provided by Web3Auth
							</h3>
							<button
								type="button"
								onClick={() => handleClaim(AddressType.Web3Auth)}
								className={`w-full py-2 px-3 text-md font-semibold rounded-md shadow-md text-white transition-all 
        ${
					isMinting
						? 'bg-violet-400 opacity-50 cursor-not-allowed'
						: 'bg-violet-700 hover:bg-violet-800 focus:ring-2 focus:ring-violet-500'
					}
      `}
								disabled={isMinting}
							>
								Mint with Web3Auth Wallet
							</button>
						</div>
					</div>
				)}
				{minted && txHash ? (
					<div>
						<p className="break-words text-violet-800 mt-3">
							Congratulations, now you have this NFT!
						</p>
						<p className="break-words text-violet-800 mt-3">
							View your transaction
							{' '}
							<a
								className="break-words text-violet-900 font-bold"
								href={`https://polygonscan.com/tx/${txHash}`}
								target="_blank"
								rel="noreferrer"
							>
								here
							</a>
						</p>
					</div>
				) : null}
			</div>
			<div className="flex flex-col w-1/2 my-4 mx-5 justify-center">
				<img
					src={nft.image}
					alt={nft.name}
					className="object-contain h-full"
				/>
			</div>
		</div>
	)
}

export default MintNft
